import React from "react";
import "../../styles/animations.css";

const VideoModal = ({ open, onClose, modalData }) => {
  return (
    <div
      id="dont-shoot-me-santa"
      className={`modal ${!open ? "fade" : "animate_video_modal"}`}
      style={{
        display: `${!open ? "none" : "block"}`,
      }}
      role="dialog"
      onClick={onClose}
    >
      <div className="modal-dialog">
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <div className="modal-header">
            <h4 className="modal-title">{modalData.title}</h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={onClose}
            >
              ×
            </button>
          </div>
          <div className="modal-body">
            <iframe
              src={modalData.url}
              width="100%"
              height={300}
              frameBorder={0}
              allow="autoplay; fullscreen"
              allowFullScreen=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoModal;
