import React, { useState } from "react";
import Menu from "../components/common/Menu";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql, Link } from "gatsby";
import VideoModal from "../components/common/VideoModal";
import "../css/movieTheater.css";

const MovieDirected = ({ data }) => {
  const movieDirected = data.sanityPage.movieDirectedTemp;
  const seo = data.sanityPage.seo;
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState({
    title: "",
    url: "",
  });

  function handleClick(isModal, title, videoUrl, externalUrl) {
    if (isModal) {
      setModalData({
        title: title,
        url: videoUrl,
      });
      setOpen(true);
    } else {
      if (typeof window !== "undefined") {
        window.open(externalUrl, "_blank");
      }
    }
  }

  function onClose() {
    setOpen(false);
  }

  return (
    <div className="movietheater_page_bg_directed">
      <div className="stuff-i-acted">
        <div className="container">
          <div className="row stuff_padd">
            <div className="col-lg-3 col-md-3 col-3">
              <Link to="/movie-theater">
                <GatsbyImage
                  image={movieDirected.back.asset.gatsbyImageData}
                  alt={movieDirected.back.asset.altText || "back to home"}
                  className="img-fluid stuff-i-acted1"
                />
              </Link>
            </div>
            <div className="col-lg-6 col-md-6 col-6">
              <center>
                <Link to="/">
                  <GatsbyImage
                    image={movieDirected.logo.asset.gatsbyImageData}
                    alt={movieDirected.logo.asset.altText || "Gubler Land"}
                    className="img-fluid stuff-i-acted2"
                    loading="eager"
                    placeholder="blurred"
                    objectFit="contain"
                  />
                </Link>
              </center>
              <center>
                <Link to="/movie-theater">
                  <GatsbyImage
                    image={movieDirected.movieTheater.asset.gatsbyImageData}
                    alt={
                      movieDirected.movieTheater.asset.altText ||
                      "Movie Theater"
                    }
                    className="img-fluid stuff-i-acted3"
                    loading="eager"
                    placeholder="blurred"
                    objectFit="contain"
                  />
                </Link>
              </center>
              <center>
                <GatsbyImage
                  image={movieDirected.directedImage.asset.gatsbyImageData}
                  alt={
                    movieDirected.directedImage.asset.altText ||
                    "Stuff I Directed"
                  }
                  className="img-fluid stuff-i-acted4"
                  loading="eager"
                  placeholder="blurred"
                  objectFit="contain"
                />
              </center>
            </div>
            <div className="col-lg-3 col-md-3 col-3">
              {/*Burger Menu Start-*/}
              <Menu />
              {/*Burger Menu End-*/}
            </div>
          </div>
          <div className="row stuff_padd">
            {movieDirected.moviesList.map((movie, index) => (
              <div className="col-6 col-lg-4 col-md-4" key={index}>
                <center>
                  <div
                    onClick={() =>
                      handleClick(
                        movie.isModal,
                        movie.title,
                        movie.videoUrl,
                        movie.externalUrl
                      )
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <GatsbyImage
                      image={movie.image.asset.gatsbyImageData}
                      alt={movie.image.asset.altText || movie.title}
                      className="img-fluid stuff-i-acted6 stuff_padd"
                      loading="lazy"
                      objectFit="contain"
                    />
                  </div>
                </center>
              </div>
            ))}
          </div>
        </div>
      </div>
      <VideoModal open={open} onClose={onClose} modalData={modalData} />
    </div>
  );
};

export const query = graphql`
  query MovieDirected($slug: String!) {
    sanityPage(slug: { current: { eq: $slug } }) {
      title
      template
      slug {
        current
      }
      seo {
        title
        pagehandle
        ldSchema
        keywords
        description
      }
      movieDirectedTemp {
        back {
          asset {
            altText
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: WEBP
            )
          }
        }
        logo {
          asset {
            altText
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: WEBP
            )
          }
        }
        movieTheater {
          asset {
            altText
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: WEBP
            )
          }
        }
        directedImage {
          asset {
            altText
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: WEBP
            )
          }
        }
        moviesList {
          title
          image {
            asset {
              altText
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: WEBP
              )
            }
          }
          isModal
          videoUrl
          externalUrl
        }
      }
    }
  }
`;
export default MovieDirected;
